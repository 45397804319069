function Plicy() {
    return (
    <div className="contact">
        <div className="contact__box">
        <div className="contact__info-box">
            <h2 >{"Conditions générales d’utilisation du site Dim3ak.com"}</h2><br/>
            <h4>1-Acceptation:</h4><br/>
            <p>
                En accédant à ce site, vous reconnaissez avoir pris connaissance des présentes informations et conditions générales d'utilisation, et reconnaissez les accepter.
            </p><br/>
            <h4>2-Déclarations:</h4><br/>
            <p>
                Les annonceurs/utilisateurs reconnaissent que le contenu des publication, y compris les textes, images, graphique ("Contenu") publié sur Dim3ak.com est exact et conforme aux lois en vigueur.
            </p><br/>
            <p>
                Dim3ak.com/ Daily Apps SARL ( Société propriétaire de la plateforme www.dim3ak.com) n'assume aucune responsabilité quant à toute illicéité ou toute erreur relative aux biens ou offre de services  annoncés sur le site.
            </p><br/>
            <p>
                L'annonceur/utilisateur convient que son Contenu ne viole aucun droit d'auteur, droits de propriété intellectuelle ou d'autres droits de toute personne ou entité.
            </p><br/>
            <p>
                Les annonceurs acceptent que leur Contenu puisse être présenté à travers les sites partenaires dim3ak.com conformément aux mêmes modalités et conditions qu’dim3ak.ma.
            </p><br/>
            <h4>3- Responsabilités:</h4><br/>
            <p>
                L'annonceur/utilisateur du site est responsable de tous dommages causés à tout tiers y compris à Dim3ak.com/la Société, du fait de l'utilisation ou de l'exploitation du site et/ou de l'un de ses éléments, et garantit Dim3ak.com /la Société des conséquences des réclamations ou actions dont Dim3ak.com /la Société pourrait, de ce fait, faire l'objet. L'utilisateur du site renonce également à exercer tout recours contre Dim3ak.com /la Société dans le cas de poursuites diligentées par un tiers à son encontre du fait de l'utilisation et/ou de l'exploitation du site.
            </p><br/>
            <p>
                Dim3ak.com /la Société ne saurait être tenu responsable de l'utilisation de Dim3ak.com et ne pourra encourir aucune responsabilité du fait de toute perte, dommage, réclamation, dette de toute nature résultant:
            </p><br/>
            (a) des erreurs ou omissions afférentes au Contenu, y compris aux inexactitudes techniques ou aux erreurs typographiques ;<br/>
            (b) de l’accès aux sites web de tiers ou de contenu directement ou indirectement accessible via les liens présents sur Dim3ak.com;<br/>
            (c) de l'indisponibilité du site Dim3ak.com ou de tout élément de ce dernier ;<br/>
            (d) de l’indisponibilité du service e-paiement occasionné par le réseau Internet ou par tout autre intervenant ;<br/>
            (e) de votre usage, licite ou illicite, du site Dim3ak.com;<br/>
            (f) de votre usage de tout équipement ou application sur Dim3ak.com;<br/>
            (g) en cas de force majeure ou de faits indépendants de la volonté de la Société.<br/>
            <h4>4- Liens vers d’autres sites:</h4><br/>
            <p>
                Dim3ak.com /la Société n'édite pas et ne contrôle pas les sites dont les liens sont proposés sur son site et dès lors, Dim3ak.com ne saurait être responsable de leurs contenus, produits, publicités ou de tous éléments ou services qu'ils présentent.
            </p><br/>
            <h4>5- Copyright:</h4><br/>
            <p>
                Le contenu des annonces déposées appartient aux annonceurs, néanmoins, en déposant des annonces sur le site, l’annonceur concède à Dim3ak.com /la Société le droit d’exploitation non exclusif, transférable, sous licenciable, à titre gracieux, pour le monde entier sur (i) l’ensemble du contenu des Annonces et notamment sur les photographies, textes, illustrations, marques, logos, titres, documents en pièce jointe (ci-après le « Contenu »), au fur et à mesure de leur publication sur le site Dim3ak.com ainsi (ii) qu’une licence sur l’ensemble des droits de propriété intellectuelle afférant au Contenu et notamment sur les droits d’auteurs sur les éléments utilisés dans son Annonce, tels que les photographies, textes, dessins, illustrations, et ce pour toute la durée légale de ses droits de propriété intellectuelle et pour le monde entier.
            </p><br/>
            <p>
                Les annonceurs accordent à Dim3ak.com /la Société le droit non-exclusif et sans redevance d’utiliser, reproduire, modifier, adapter, publier, traduire, créer à partir du Contenu d’autres œuvres et de distribuer le Contenu ou l’incorporer à n'importe quelle forme, moyen ou technologie connue ou développée plus tard, dans le cadre du service Dim3ak.com /la Société ou en relation avec ses activités, et ce à des fins commerciales ou non et notamment publicitaires, ainsi que dans le cadre d’une diffusion sur les réseaux sociaux sur lesquels Dim3ak.com /la Société sont présents.
            </p><br/>
            <h4>6- Sécurité et images:</h4><br/>
            <p>
                Dim3ak.com se réserve le droit de changer le titre du Contenu à des fins éditoriales. Dim3ak.com se réserve aussi le droit de ne pas publier des images qui n'ont aucun rapport avec le Contenu, ou des images ne respectant pas les règles de Dim3ak.com ou qui contiennent le logo d’un site concurrent.
            </p><br/>
            <h4>8- Accès au service:</h4><br/>
            <p>
                Le service d’utilisation du site Dim3ak.com est accessible gratuitement à tout utilisateur disposant d'un accès à internet. Les coûts afférents à l'accès à ce service, notamment les coûts du matériel, des logiciels ou de l’accès à internet sont exclusivement à la charge de l'utilisateur. Il est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à internet.
            </p><br/>
            <p>
                Certaines sections du site sont réservées aux membres inscrits sur le site Dim3ak.com après qu’ils aient procédé à leur identification à l'aide de leur identifiant et de leur mot de passe.
            </p><br/>
            <p>
                La Société se réserve le droit de refuser l'accès aux services disponibles sur le site Dim3ak.com , unilatéralement et sans notification préalable, à tout utilisateur ne respectant pas les présentes conditions d'utilisation.
            </p><br/>
            <p>
                La Société met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité aux services, mais n'est tenu à aucune obligation d'y parvenir.
            </p><br/>
            <p>
                La Société ne peut, en outre, être tenue responsable de tout dysfonctionnement du réseau ou des serveurs ou de tout autre événement échappant au contrôle raisonnable, qui empêcherait ou dégraderait l'accès aux services.
            </p><br/>
            <p>
                La Société se réserve la possibilité d'interrompre, de suspendre momentanément ou de modifier sans préavis l'accès à tout ou partie des services, afin d'en assurer la maintenance, ou pour toute autre raison, sans que l'interruption n'ouvre droit à aucune obligation ni indemnisation.
            </p><br/>
            <h4>9- IDENTIFIANT ET MOT DE PASSE:</h4><br/>
            • Identifiant : Le terme « Identifiant » recouvre les informations nécessaires à l'identification d'un utilisateur sur le site pour accéder aux zones réservées aux membres.<br/>
            • Mot de passe : Le « Mot de passe » est une information confidentielle, dont l'utilisateur doit garder le secret, lui permettant, utilisé conjointement avec son Identifiant, de prouver son identité.<br/>
            <p>
                L'accès à certaines sections du site Dim3ak.com et notamment du service« mon compte » ou ma « boutique en ligne » nécessite l'utilisation d'un Identifiant et d'un Mot de passe. Le Mot de passe, choisi par l'utilisateur, est personnel et confidentiel. L'utilisateur s'engage à conserver son mot de passe secret et à ne pas le divulguer sous quelque forme que ce soit. L'utilisation de son Identifiant et de son Mot de passe à travers internet se fait aux risques et périls de l'utilisateur. Il appartient à l'utilisateur de prendre toutes les dispositions nécessaires permettant de protéger ses données contre toute atteinte.
            </p><br/>
            <p>
                La Société s'engage néanmoins à mettre en place tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données transmises. L'utilisateur est informé qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourront être placés sur son disque dur afin d'assurer son identification.
            </p><br/>
            <p>
                L'utilisateur admet connaître les limitations et contraintes propres au réseau internet et, à ce titre, reconnaît notamment l'impossibilité d'une garantie totale de la sécurisation des échanges de données. La Société ne pourra pas être tenue responsable des préjudices découlant de la transmission de toute information, y compris de celle de l’Identifiant et/ou du Mot de passe, via les services disponibles sur le site.
            </p><br/>
            <p>
                L'Utilisateur s'engage, d'une manière générale, à respecter l'ensemble de la réglementation en vigueur au Maroc et celle de son pays de résidence.
            </p><br/>
            <h4>10- RESPECT DE LA VIE PRIVEE:</h4><br/>
            <p>
                La Société utilise vos informations uniquement dans le cadre prévu par la loi marocaine et dans le respect absolu de la vie privée.
            </p><br/>
            <p>
                Pour la Société, le respect de la vie privée est un principe essentiel de la communauté.
            </p><br/>
            <p>
                La Société stocke et traite vos informations sur des ordinateurs situés au Maroc et  ailleurs et qui sont protégés par des méthodes de sécurité aussi bien physiques que technologiques.
            </p><br/>
            <p>
                Vous pouvez consulter et modifier les informations que vous nous fournissez, et choisir de ne pas recevoir certains types de communications lors de l'ouverture de votre compte ou à tout autre moment. Nous utilisons des tiers pour vérifier et certifier nos principes de respect de la vie privée.
            </p><br/>
            <h4>11- LE TRAITEMENT DES DONNEES:</h4>

                    <p>Lorsque vous utilisez les services proposés par la Société, vous acceptez que vos données personnelles soient transmises à l’entité suivante:</p>
                    • La société Daily Apps SARL AU de droit Marocain immatriculée au Registre du Commerce de Rabat sous le numéro xxxxet dont le siège social est situé au Rabat
                    <h4>12- COLLECTE DES DONNEES PERSONNELLES:</h4>
                    <p>Vous pouvez naviguer sur Dim3ak.com sans avoir à décliner votre identité ou nous fournir une quelconque information. Lorsque vous nous communiquez vos informations personnelles, vous perdez votre anonymat. Si vous choisissez de nous les fournir, vous acceptez le transfert et le stockage de ces informations sur nos serveurs situés au Maroc et ailleurs et en tout autre lieu dans le monde où la Société possède des locaux.</p>

                    <p>Nous pouvons recueillir et enregistrer les données personnelles suivantes :</p>

                    • le nom, l'adresse e-mail, la date d’inscription, la ville ainsi que le quartier correspondant, le(s) numéro(s) de téléphone, coordonnées Skype, l’URL de votre site Web,<br/>
                                    • les coordonnées physiques, la date de naissance et (selon le service utilisé) des informations financières, telles que les numéros de carte de crédit ou les numéros de compte bancaire ;<br/>
                                    • les informations relatives à vos activités sur le site (telles que les demandes de livraisons, les offres de voyages, les objets et le contenu que vous générez ou qui sont liés à votre compte) ;<br/>
                                    • les conversations en messagerie, la résolution de litiges, la correspondance sur nos sites et la correspondance qui nous est envoyée ;<br/>
                                    • les informations résultant de votre interaction avec nos sites, services, contenus et publicités, y compris les informations relatives à votre ordinateur et votre connexion, des statistiques sur les pages affichées, les données de navigation, les données publicitaires, votre adresse IP et des informations de blog standard ;<br/>
                                    • les informations supplémentaires que nous vous demandons d'envoyer pour vous authentifier ou si nous pensons que vous enfreignez le règlement du site (par exemple, nous pouvons vous demander de nous envoyer une pièce d'identité ou une facture pour vérifier votre adresse, ou pour répondre à des questions supplémentaires afin de vérifier votre identité) ; ou toute autre façon justifiée de recueillir des informations permettant de vérifier votre identité.<br/>
                    <h4>13- MARKETING:</h4>
                    <p>La Société ne vend ni ne loue vos données personnelles à des tiers à des fins de marketing sans votre consentement formel.</p>

                    <p>La Société peut combiner vos données avec d’autres données ainsi que recueillir des données auprès d'autres sociétés et les utiliser pour améliorer et personnaliser ses services, contenus et publicités.</p>

                    <h4>14- UTILISATION DES DONNEES PERSONNELLES:</h4>
                    <p>L'objectif principal de la collecte de données personnelles est de vous offrir une expérience sûre, optimale, efficace et personnalisée. Vous acceptez que nous puissions utiliser vos données personnelles et notamment afin de :</p>

                    • fournir nos services et notamment le service client que vous demandez ;<br/>
                                    • résoudre les litiges, percevoir des frais et remédier à des problèmes ;<br/>

                                    • empêcher, détecter et enquêter sur toutes activités potentiellement interdites ou illégales, et faire appliquer nos conditions générales d'utilisation ;<br/>

                                    • personnaliser, évaluer et améliorer nos services, contenus et publicités ;<br/>

                                    • vous informer sur nos services et ceux de nos sociétés liées, par du marketing ciblé, des mises à jour de service et des offres promotionnelles basées sur vos préférences de communication ;<br/>

                                    • comparer les informations pour s'assurer de leur exactitude, et les vérifier auprès de tiers.<br/>

                    <h4>15- PARTAGE DE VOS DONNEES:</h4>
                    <p>La Société peut divulguer vos données personnelles, telles que votre identité, votre adresse IP, adresse email ou numéro de téléphone, pour satisfaire à des obligations légales, pour faire appliquer les présentes conditions générales, pour répondre à des réclamations liées à une annonce ou du contenu en violation avec les droits d'autres utilisateurs, ou pour préserver les droits, la propriété ou la sécurité de quiconque.</p>

                    <p>Nous pouvons également communiquer vos données personnelles :</p>
                                    • à des sociétés liées à la Société telle que la maison mère, pour fournir du contenu et des services communs (comme l'inscription, les transactions et le service clients), pour détecter et empêcher des agissements potentiellement illégaux et pour orienter leurs décisions concernant leurs produits, services et communications. Les sociétés liées utiliseront ces données pour vous envoyer des communications marketing, uniquement dans la mesure où vous avez sollicité leurs services ;<br/>

                                    • à des prestataires de services sous contrat qui nous assistent dans nos activités opérationnelles (par exemple, les investigations sur les fraudes, le recouvrement des créances, les programmes d'affiliation et de récompense, et assistance client) ;<br/>

                                    • à d'autres tiers à qui vous nous avez expressément demandé d'envoyer vos données.<br/>
                                    • aux autorités judiciaires, administratives et publiques, en réponse à une demande de renseignements relative à une enquête criminelle, des allégations d’activités illégales ou toute autre activité pouvant engager votre responsabilité civile, la nôtre, ou celle d’un autre utilisateur du site Dim3ak.com. Pourront figurer parmi les informations personnelles que nous divulguerons votre Identifiant et son historique, votre nom, ville, région, numéro de téléphone, adresse e-mail, les plaintes pour fraude vous concernant, l’historique de vos demandes et offres, ou toute autre information que nous pourrions estimer utile ;<br/>
                                    • à d'autres entités commerciales, dans le cas où la Société ferait l'objet d'une fusion ou d'une acquisition par une autre société. (Dans une telle hypothèse, nous veillerons à ce que la nouvelle entité applique les stipulations des présentes notamment relativement au respect de la vie privée concernant vos données à caractère personnel.)<br/>
                    <p>Sans limiter ce qui précède, par souci d'assurer le respect de votre vie privée et de maintenir les personnes malveillantes à l'écart de vos données personnelles, nous ne communiquerons pas vos données personnelles à des autorités judiciaires, à des autorités publiques ou à des tiers, sans citation à comparaître, ordonnance de tribunal ou autre procédure légale similaire, sauf si nous estimons de bonne foi que la transmission des données est nécessaire pour prévenir un dommage matériel ou un préjudice financier imminent, ou encore une suspicion d’activités illégales.</p>


                    <p>Dim3ak.com/la Société se réserve le droit de rechercher toutes les voies de recours légalement disponibles en cas de violation des conditions d’utilisation du site et notamment le droit de bloquer l'accès au site et à ses fonctionnalités</p>
                    <h4>16- INFORMATIONS QUE VOUS PARTAGEZ SUR DIM3AK:</h4>
                    <p>En tant que professionnel, votre Identifiant et le nom de votre « Société » apparaissent sur l’ensemble du site Dim3ak.com et sont donc visibles par tout le monde.</p>

                    <p>Par conséquent, si vous associez votre nom à votre pseudo, les personnes à qui vous avez révélé votre nom seront en mesure d'identifier personnellement vos activités sur le site Dikm3ak.com. Si vous êtes un professionnel, vous devez communiquer votre identité et vos coordonnées (par exemple le nom et l'adresse géographique) lorsque vous publier une offre.</p>

                    <p>Si vous accédez au site Dikm3ak.com à partir d'un ordinateur partagé ou d'un ordinateur dans un cybercafé, certaines de vos données personnelles peuvent être également visibles par d'autres personnes utilisant l'ordinateur après vous. Sur un ordinateur partagé, nous vous recommandons de vous déconnecter du site Dim3ak.com et de supprimer vos cookies.</p>
                    <h4>h17- UTILISATION D'INFORMATIONS DIM3AK:</h4>
                    <p>La Société vous permet de communiquer des informations personnelles et financières pour effectuer des transactions. Nous vous encourageons à communiquer vos pratiques en matière de respect de la vie privée et à respecter la vie privée des autres utilisateurs.</p>

                    <p>Etant donné que nous ne pouvons pas garantir la confidentialité ou la sécurité de vos données, nous vous encourageons à évaluer la politique de confidentialité et de sécurité de votre partenaire commercial avant d'entamer une transaction et de choisir de communiquer vos données.</p>
                    <h4>18- COOKIES:</h4>
                    <p>Nous utilisons des « cookies » (petits fichiers placés sur votre disque dur) sur certaines de nos pages pour mieux analyser le flux généré par notre page internet, personnaliser nos services, notre contenu et nos publicités, mesurer l’efficacité promotionnelle, et promouvoir la confiance et la sécurité.</p>

                    <p>Voici quelques éléments à savoir sur les cookies :</p>
                                    • Nous proposons des fonctionnalités qui ne sont disponibles que grâce aux cookies.<br/>
                                    • Nous utilisons des cookies pour vous identifier et vous permettre de rester connecté.<br/>
                                    • La plupart des cookies sont des « cookies de session », ce qui signifie qu'ils sont automatiquement supprimés de votre disque dur à la fin d'une session.<br/>
                                    • Vous avez toujours la possibilité de refuser nos cookies si votre navigateur le permet, mais dans ce cas, certains de nos sites et services risquent de ne plus vous êtes accessibles.<br/>
                                    • Vous rencontrerez peut-être des cookies de tiers sur certaines pages de sites sur lesquels nous n'avons pas de contrôle. (Par exemple, si vous consultez une page Web créée par un autre utilisateur, un cookie vous sera peut-être envoyé par cette page Web.)<br/>
                    <h4>19- PROTECTION DE COMPTE:</h4>
                    <p>Votre Mot de passe est la clé d'accès à votre compte. Utilisez des chiffres, des lettres et des caractères spéciaux uniques, et ne communiquez votre Mot de passe à personne. Si vous communiquez votre Mot de passe ou vos informations personnelles à d'autres personnes, gardez à l'esprit que vous êtes responsable de toutes les actions effectuées depuis votre compte. Si vous perdez le contrôle de votre mot de passe, vous risquez de perdre le contrôle d'une importante partie de vos données personnelles et votre responsabilité légale est engagée pour toute action effectuée en votre nom. Par conséquent, si la confidentialité de votre Mot de passe a été compromise pour une raison quelconque, vous devez immédiatement prévenir la Société et modifier votre Mot de passe.</p>
                    <h4>20- DROIT D'ACCES ET DE RECTIFICATION DE VOS DONNEES PERSONNELLES:</h4>
                    <p>Conformément à la loi 09-08 promulguée par le Dahir 1-09-15 du 18 février 2009 relative à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, vous disposez d’un droit d’accès, de rectification, et d’opposition des données relatives aux informations vous concernant. Vous pouvez l’exercer par courrier électronique à l’adresse suivante : support@dim3ak.com. Vous pouvez également, pour des motifs légitimes, vous opposer à ce que les données qui vous concernent fassent l’objet d’un traitement. Ce traitement a été notifié et autorisé par la CNDP par la déclaration de traitement n° D-GC-158/2016.</p>

                    <p>Généralement, nous ne modifierons pas manuellement vos données personnelles parce qu'il est très difficile de vérifier votre identité à distance. Si vos données personnelles changent ou sont inexactes vous devez les modifier sans délai à travers votre compte ou sur votre demande à notre service clients en envoyant un email à l’adresse mail support@dim3ak.com nous fermerons votre compte et retirerons vos données personnelles de la partie publique du site aussi vite que possible, en fonction des activités de votre compte et conformément aux dispositions applicables. Toutefois, nous conservons dans nos systèmes les données personnelles des comptes fermés afin de nous conformer aux dispositions légales en vigueur, de prévenir les fraudes, de recouvrir les montants dus, de résoudre tout litige, de régler tout problème, de collaborer à toute enquête, de faire appliquer nos conditions générales d'utilisation ou de prendre toute autre action permise par la loi.</p>

                    <h4>21- SECURITE:</h4>
                    <p>Vos données sont enregistrées sur nos serveurs situés au Maroc ou ailleur. Nous considérons vos données comme un actif qui doit être protégé et utilisons de nombreux outils (cryptage, mots de passe, sécurité physique, etc.) pour les préserver de tout accès ou divulgation non autorisée. Toutefois, comme vous le savez probablement, des tiers sont susceptibles d'intercepter des envois ou des communications privées ou d'y accéder de façon illégale, et d'autres utilisateurs peuvent faire une utilisation abusive ou inappropriée de vos données personnelles recueillies sur le site. En conséquence, bien que nous mettions tout en œuvre pour assurer le respect de votre vie privée, nous ne pouvons pas garantir que vos données personnelles ou communications privées resteront toujours anonymes.</p>

                    <h4>22- TIERS:</h4>

                    <p>Sauf stipulation contraire aux présentes dispositions, tout ce qui précède ne s'applique qu'à l'utilisation et la divulgation des données que nous recueillons de votre part. Si vous communiquez vos données à d'autres personnes, qu'il s'agisse d’un utilisateur sur nos sites ou d'autres sites sur Internet, différents règlements peuvent être applicables en ce qui concerne l'utilisation ou la divulgation des informations que vous leur fournissez. La Société n'a aucun contrôle sur les politiques des tiers en matière de respect de la vie privée, et vous êtes soumis aux règlements de ces tiers. Nous vous encourageons à poser des questions avant de communiquer vos données personnelles à d'autres personnes.</p>
                    <h4>23- Indemnisation:</h4>
                    <p>Les annonceurs/utilisateurs s'engagent à indemniser Dim3ak.com, ses dirigeants, administrateurs, employés et agents contre toutes les pertes, dépenses, dommages et coûts, y compris les honoraires d'avocats, résultant de toute violation des conditions générales d’utilisation du site Dim3ak.com.</p>

                    <p>Les annonceurs/utilisateurs s'engagent à indemniser Dim3ak.com, ses dirigeants, administrateurs, employés et agents contre tout comportement fautif ou négligent de la part des annonceurs/utilisateurs.</p>
                    <h4>24- Modification:</h4>
                    <p>Dim3ak.com /la Société se réserve le droit de modifier les conditions générales d’utilisation du site à n'importe quel moment. Toute modification entrera en vigueur immédiatement après sa publication sur Dim3ak.com. Vous êtes tenus de consulter régulièrement le site et votre accès régulier ou usage du site Dim3ak.com induisent votre approbation des modalités et conditions modifiées.</p>
                    <h4>25- REFERENCES LEGALES:</h4>
                    <p>L’annonceur et la Société sont soumis au droit marocain et notamment les textes de lois suivants :</p>
                                    • Dahir n° 1-07-129 du 19 kaada 1428 portant promulgation de la loi n° 53-05 relative à l'échange électronique de données juridiques.<br/>
                                    • Décret n° 2-08-518 du <br/>25 joumada i 1430 pris pour l'application des articles 13, 14, 15, 21 et 23 de la loi n° 53-05 relative à l'échange électronique des données juridiques.<br/>
                                    • Dahir n° 1-09-15 du 22 safar 1430 portant promulgation de la loi n° 09-08 relative à la protection des personnes physiques à l'égard du traitement des données à caractère personnel.<br/>
                                    • Décret n° 2-09-165 du 25 joumada i 1430 pris pour l'application de la loi n° 09-08 relative à la protection des personnes physiques à l'égard des traitements des données à caractère personnel.<br/>
                                    • Décision du premier ministre n°3-33-11 en date du 28 mars 2011 approuvant le règlement intérieur de la commission nationale de protection des données à caractère personnel.<br/>
                                    • Dahir n° 1-11-03 du 14 rabii I 1432 portant promulgation de la loi n° 31-08 édictant des mesures de protection du consommateur.<br/>
                                    • Dahir n° 1-03-197 du 16 ramadan 1424 portant promulgation de la loi n° 07-03 complétant le code pénal en ce qui concerne les infractions relatives aux systèmes de traitement automatisé des données.<br/>
                    <h4>26- MENTIONS LEGALES RELATIVES A LA SOCIETE:</h4>
                                    • Daily Apps Société à responsabilité limitée de droit marocain au capital social de 100 000  dirhams,<br/>
                                    • Immatriculée au registre du commerce de Rabat sous le numéro xxx xxx,<br/>
                    <h4>27- Tribunaux compétents:</h4>
                    <p>Tout litige ou réclamation afférents à l'utilisation du site Dim3ak.com sera de la compétence exclusive du tribunal de commerce de Rabat.</p>
            </div>
        </div>
    </div>
);}

export default Plicy;